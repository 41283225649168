// React imports
import { createContext, useEffect, useRef, useState } from 'react'

// Remix imports

// Third party imports
import { useFetcher, useNavigation } from '@remix-run/react'
import Cookies from 'js-cookie'
import useLang from '~/hooks/useLang'
import useLoadData from '~/hooks/useLoadData'
import type { UserDataLoaderData } from '~/routes/$lang.components/user-data'

// Generated imports

// App imports

export const getUserDataRoute = (lang: string, query?: string) =>
  `/${lang}/components/user-data/${query ? query : ''}`

export const UserDataContext = createContext<UserDataLoaderData>({
  hasInteracted: false,
  currentProfile: undefined,
  isAuthenticated: false,
  isStaff: false,
  isSuperuser: false,
  isTradeUser: false,
})

interface Props {
  children?: any
}

export const UserDataContextProvider: React.FC<Props> = ({ children }) => {
  /**
   * This is wrapped around the entire app so that all our components can use
   * `useUserData()` in order to find out if the current user isSuperuser,
   * isStaff, isAuthenticated, hasInteracted.
   *
   * If the user is logged in, we also have access to the
   * CurrentProfileFragment, which contains information like userProfileType.
   */
  const lang = useLang()

  const [isReloading, setIsReloading] = useState(false)
  const [isMounted, setIsMounted] = useState(false)
  const [hasInteracted, setHasInteracted] = useState(false)
  const hasInteractedCookie = Cookies.get('hasInteracted')
  const hasSubmittedRef = useRef(false)
  const hasInteractedFetcher = useFetcher()
  const userDataFetcher = useFetcher<UserDataLoaderData>()

  useEffect(() => {
    setIsMounted(true)

    const handleUserInteraction = () => {
      if (isMounted && !hasInteractedCookie && !hasSubmittedRef.current) {
        hasSubmittedRef.current = true
        setHasInteracted(true)
        hasInteractedFetcher.submit(
          {},
          { method: 'post', action: `/${lang}/components/has-interacted/` }
        )
      }
    }

    document.addEventListener('focus', handleUserInteraction, { once: true })
    document.addEventListener('mousemove', handleUserInteraction, {
      once: true,
    })
    document.addEventListener('scroll', handleUserInteraction, { once: true })
    document.addEventListener('touchstart', handleUserInteraction, {
      once: true,
    })
    document.addEventListener('keypress', handleUserInteraction, { once: true })

    return function cleanup() {
      document.removeEventListener('focus', handleUserInteraction, false)
      document.removeEventListener('mousemove', handleUserInteraction, false)
      document.removeEventListener('scroll', handleUserInteraction, false)
      document.removeEventListener('touchstart', handleUserInteraction, false)
      document.removeEventListener('keypress', handleUserInteraction, false)
    }
  }, [lang, hasInteractedFetcher, isMounted, hasInteractedCookie])

  useEffect(() => {
    if (hasInteractedCookie) {
      setHasInteracted(true)
    }
  }, [hasInteractedCookie])

  useLoadData(
    'UserDataContext',
    userDataFetcher,
    getUserDataRoute(lang),
    hasInteracted
  )

  /**
   * This is a bit of a hack. When hasInteracted happens, we trigger a POST
   * to /components/has-interacted/ (see above), which will set the cookie,
   * but that will not re-fetch user-data (since that is not fetched as part
   * of any route). By setting it manually here, all components have the most
   * up-to-date value of hasInteracted available (especially GTM needs it).
   */
  const data = userDataFetcher.data || ({} as any)
  if (
    data &&
    userDataFetcher.state === 'idle' &&
    userDataFetcher.data != null &&
    hasInteracted
  ) {
    data.hasInteracted = hasInteracted
  }

  const navigation = useNavigation()

  // it seems we don't need this any more with Remix 1.12:

  // // If we submit a form anywhere, we want to re-fetch the user-data
  // const userDataFetcherLoad = userDataFetcher.load
  // useEffect(() => {
  //   if (
  //     ['actionReload', 'actionRedirect'].includes(transition.type) &&
  //     !isReloading
  //   ) {
  //     setIsReloading(true)
  //     // this line causes other loader requests to be cancelled (ie in cart)
  //     userDataFetcherLoad(getUserDataRoute(lang, '?reload=1'))
  //   }
  // }, [
  //   isReloading,
  //   transition.state,
  //   transition.type,
  //   userDataFetcher.state,
  //   userDataFetcherLoad,
  //   lang,
  // ])

  useEffect(() => {
    if (
      isReloading &&
      navigation.state === 'idle' &&
      userDataFetcher.state === 'idle'
    ) {
      setIsReloading(false)
    }
  }, [isReloading, navigation.state, userDataFetcher.state])

  return (
    <UserDataContext.Provider value={data}>{children}</UserDataContext.Provider>
  )
}
