// React imports

// Remix imports
import type { FetcherWithComponents } from '@remix-run/react'
import { useEffect } from 'react'

// Third party imports

// Generated imports

// App imports

const useLoadData = (
  name: string,
  fetcher: FetcherWithComponents<any>,
  route: string,
  hasInteracted?: boolean | null
) => {
  useEffect(() => {
    if (hasInteracted) {
      if (fetcher.state === 'idle' && fetcher.data == null) {
        fetcher.load(route)
      }
    }
  }, [hasInteracted, fetcher, route])
}

export default useLoadData
