// React imports
import { useContext } from 'react'

// Remix imports

// Third party imports

// Generated imports

// App imports
import { UserDataContext } from '~/components/performance/UserDataContextProvider'

const useUserData = () => {
  const userData = useContext(UserDataContext)
  return userData
}

export default useUserData
